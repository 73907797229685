import React, { Component } from 'react';

export default class Banner extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "#f7f7f7", padding: "10px"}}>
        <span style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          <img
            alt=""
            src="uhealth-logo.svg"
            width="200"
          />
        </span>
        <span className="banner-text" style={{ display: "flex", justifyContent: "center", fontSize: "25px" }} >Device Tests</span>
      </div>
    )
  }
}