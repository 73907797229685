import './App.css';
import Banner from './Components/Navbar'
import platform from 'platform';
import Footer from './Components/Footer'
import { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import React, { Suspense } from 'react';
import { ProgressBarSleepTime } from './utilities/utilities'

const DeviceTestComponent = React.lazy(() => import('./Components/DeviceTests'));
const NetworkTestComponent = React.lazy(() => import('./Components/NetworkTests'));

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportInfoHiddenStatus: "hidden",
      DeviceTestsComplete: false,
      NetworkTestsComplete: false,
      failedMinimumPlatformRequirementsTextHidden: "checkingVersion",
      failedMinimumPlatformRequirementsText: "",
      versionCheckingStatusLoaderHidden: "inline",
      deviceTestRow: "DeviceTest",
      networkTestRow: "NetworkTest",
      minimumPlatformRequirements: {
        Windows: {
          OS: "10",
          Chrome: "83.0"
        },
        OSX: {
          OS: "10.14.0",
          Chrome: "83.0",
          Safari: "13.1.1"
        },
        iOS: {
          OS: "13.6",
        },
        Android: {
          OS: "8",
          Chrome: "85.0"
        }
      }
    }
  }

  testCompleted = (testName, testResults) => {
    switch (testName) {
      case "device":
        this.setState({
          DeviceTestsComplete: true,
          DeviceTestsPassed: testResults
        })
        break;
      case "network":
        this.setState({
          NetworkTestsComplete: true,
          NetworkTestsPassed: testResults
        })
        break;
      default:
        console.log("Shouldn't reach this point")
    }
    if (this.state.DeviceTestsComplete === true && this.state.NetworkTestsComplete === true) {
      let combinedResult = (this.state.NetworkTestsPassed === "Passed") && (this.state.DeviceTestsPassed === "Passed") ? "Passed"
        : ((this.state.NetworkTestsPassed === "Failed") || (this.state.DeviceTestsPassed === "Failed") ? "Failed"
          : ((this.state.DeviceTestsPassed === "Caution-speaker") ? "Caution-speaker" : "Caution"))

      this.setState({
        supportInfoHiddenStatus: combinedResult
      })
    }
  }

  //minimum platform requirements https://support.vidyocloud.com/hc/en-us/articles/360025169474-VidyoConnect-for-WebRTC-Native-Transcoded-Releases#Compatibility%20(Native)
  checkMinimumPlatformRequirements() {
    this.setState({
      versionCheckingStatusLoaderHidden: "none"
    });

    let passedTests = true;

    const clientPlatformOSFamily = platform.os.family
    const clientPlatformOSVersion = platform.os.version !== null ? platform.os.version.split(".") : platform.os.version
    const clientPlatformBrowserName = platform.name
    const clientPlatformBrowserVersion = platform.version !== null ? platform.version.split(".") : platform.version

    //Windows®	10 and later	- Google Chrome™ 83 and later
    if (clientPlatformOSFamily === "Windows") {
      if (!this.compareVersions(this.state.minimumPlatformRequirements.Windows.OS.split("."), clientPlatformOSVersion)) {
        passedTests = false;
      }
      if (clientPlatformBrowserName === "Chrome") {
        if (!this.compareVersions(this.state.minimumPlatformRequirements.Windows.Chrome.split("."), clientPlatformBrowserVersion)) {
          passedTests = false;
        }
      }
      else {
        passedTests = false;
      }
    }
    //macOS®	10.14 and later	- Google Chrome 83 and later - Safari® 13.1.1 and later
    else if (clientPlatformOSFamily === "OS X") {
      if (!this.compareVersions(this.state.minimumPlatformRequirements.OSX.OS.split("."), clientPlatformOSVersion)) {
        passedTests = false;
      }
      else {
        if (clientPlatformBrowserName === "Chrome") {
          if (!this.compareVersions(this.state.minimumPlatformRequirements.OSX.Chrome.split("."), clientPlatformBrowserVersion)) {
            passedTests = false;
          }
        }
        else if (clientPlatformBrowserName === "Safari") {
          if (!this.compareVersions(this.state.minimumPlatformRequirements.OSX.Safari.split("."), clientPlatformBrowserVersion)) {
            passedTests = false;
          }
        }
        else {
          passedTests = false;
        }
      }
    }
    //iOS/iPad OS	13.6 and later	Safari
    else if (clientPlatformOSFamily === "iOS") {
      if (!this.compareVersions(this.state.minimumPlatformRequirements.iOS.OS.split("."), clientPlatformOSVersion)) {
        passedTests = false;
      }
      if (clientPlatformBrowserName !== "Safari") {
        passedTests = false;
      }
    }
    //Android™ 	8 and later	Chrome 85 and later
    else if (clientPlatformOSFamily === "Android") {
      if (!this.compareVersions(this.state.minimumPlatformRequirements.Android.OS.split("."), clientPlatformOSVersion)) {
        passedTests = false;
      }
      if (clientPlatformBrowserName === "Chrome" || clientPlatformBrowserName ===  "Chrome Mobile") {
        if (!this.compareVersions(this.state.minimumPlatformRequirements.Android.Chrome.split("."), clientPlatformBrowserVersion)) {
          passedTests = false;
        }
      }
      else{
        passedTests = false;
      }
    }
    // android disguised as linux
    else if (clientPlatformOSFamily === "Linux") {
      if (clientPlatformBrowserName !== "Chrome") {
        passedTests = false;
      }
      else if (clientPlatformBrowserName === "Chrome") {
        if (!this.compareVersions(this.state.minimumPlatformRequirements.Android.Chrome.split("."), clientPlatformBrowserVersion)) {
          passedTests = false;
        }
      }
    }
    //OS unsupported
    else {
      passedTests = false;
    }

    //import DeviceTests and NetworkTests Components
    if (!passedTests) {
      this.setState({
        failedMinimumPlatformRequirementsText:
          `Detected OS: "${clientPlatformOSFamily === null ? "Unknown" : clientPlatformOSFamily} ${clientPlatformOSVersion === null ? "Unknown" : platform.os.version}".\nDetected Browser: "${clientPlatformBrowserName === null ? "Unknown" : clientPlatformBrowserName} ${clientPlatformBrowserVersion === null ? "Unknown" : platform.version}".`
      })
    }

    this.setState({
      failedMinimumPlatformRequirementsTextHidden: passedTests === false ? "inline" : "none",
    });
  }

  compareVersions(minimumSupportedVersion, versionToCheck) {
    let versionToCheckMeetsRequirements = true;
    const numSubVersionToCheck = minimumSupportedVersion.length < versionToCheck.length ? minimumSupportedVersion.length : versionToCheck.length

    for (let i = 0; i < numSubVersionToCheck; i++) {
      const clientVersion = parseInt(versionToCheck[i])
      const minVersion = parseInt(minimumSupportedVersion[i])
      if ( clientVersion < minVersion) {
        versionToCheckMeetsRequirements = "none";
        break;
      }
      else if (clientVersion > minVersion) {
        break;
      }
    }

    return versionToCheckMeetsRequirements;
  }

  componentDidMount() {
    //set timeout to create a thinking effect, loader displayed while waiting
    setTimeout(() => { this.checkMinimumPlatformRequirements() }, ProgressBarSleepTime())
  }

  render() {
    return (
      < >
        <Banner />

        {/* Precheck tests */}
        {this.state.failedMinimumPlatformRequirementsTextHidden === "none" &&
          <Container className="mt-4">
            {/* Device tests */}
            <Row className="mb-4 justify-content-center">
              <Col xs="auto">
                <Suspense fallback={<img alt="loading" src="loading.gif" />}>
                  <DeviceTestComponent testCompleted={this.testCompleted} />
                </Suspense>
                {/* {this.state.deviceTestRow} */}
              </Col>
            </Row>
            {/* Network tests */}
            <Row className="mb-4 justify-content-center">
              <Col xs="auto">
                <Suspense fallback={<img alt="loading" src="loading.gif" />}>
                  <NetworkTestComponent testCompleted={this.testCompleted} />
                </Suspense>
              </Col>
            </Row>
          </Container>
        }

        {/* Version tests */}
        {this.state.failedMinimumPlatformRequirementsTextHidden === "checkingVersion" &&
          <Container className="mt-4">
            <Row className="mb-2 justify-content-center">
              <Col xs="auto">
                <h2 style={{ textAlign: "center" }}>Checking software versions</h2>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col xs="auto">
                <img alt="loading" src="loading.gif" />
              </Col>
            </Row>
          </Container>
        }

        {/* Version check failed */}
        {this.state.failedMinimumPlatformRequirementsTextHidden === "inline" &&
          <Container>
            <Row className="mt-2 mb-2 justify-content-center">
              <h2 style={{ textAlign: "center" }}>Device or Browser not supported.<br />Please switch to a supported version and try again.</h2>
            </Row>
            <Row className="mt-2 mb-2 justify-content-center">
              <Col xs="auto">
                <br />
                <span style={{ whiteSpace: "break-spaces", textAlign: "center" }}>
                  <div style={{ textAlign: "left" }}>{this.state.failedMinimumPlatformRequirementsText}</div>
                  <br />
                  <h2>Supported Platforms</h2>
                </span>
                <div><img alt="compatibility chart" src="/compatibility-chart.png" className="center-block" width="600px" style={{ border: "5px solid", maxHeight: "100%", maxWidth: "100%" }} /></div>
                <br />
              </Col>
            </Row>
          </Container>
        }

        {/* Support info */}
        {this.state.supportInfoHiddenStatus !== "hidden" &&
          <Container>
            <Row className="mb-4 justify-content-center" style={{ fontSize: "20px" }}>
              <Col xs="auto" style={{ textAlign: "center" }}>
                {this.state.supportInfoHiddenStatus === "Passed" &&
                  <div>Your device has <b>passed</b> our tests and should work!<br />For MyChart support call <a href="tel:8012135555"><b>801-213-5555</b></a>.</div>
                }
                {this.state.supportInfoHiddenStatus === "Caution" &&
                  <div>Your device passed our tests with <b>caution</b> and may function normally.<br />For MyChart support call <a href="tel:8012135555"><b>801-213-5555</b></a>.</div>
                }
                {this.state.supportInfoHiddenStatus === "Caution-speaker" &&
                  <div>Your device passed our tests with <b>caution</b> please test your speakers manually or by clicking the "test speakers" button above.<br />For MyChart support call <a href="tel:8012135555"><b>801-213-5555</b></a>.</div>
                }
                {this.state.supportInfoHiddenStatus === "Failed" &&
                  <div>Your device has <b>failed</b> a test,<br />Please call MyChart support at <a href="tel:8012135555"><b>801-213-5555</b></a>, so we can assist you and ensure you'll be ready for your virtual visit.</div>
                }
              </Col>
            </Row>
          </Container>
        }

        {/* Footer */}
        <Container>
          <Row className=" mb-3 justify-content-center">
            <Col xs="auto">
              <Footer />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}